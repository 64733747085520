<template>
  <div>
    <afficheNormal  v-if="!$store.state.user.elderModel" />
    <afficheElder v-else/>

  </div>
</template>

<script>
import afficheNormal from './afficheNormal.vue'
import afficheElder from './affiche-elder.vue'
export default {
  name: '',
  components: {
    afficheNormal,
    afficheElder
  },
  data() {
    return {}
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style  scoped>
</style>
