<template>
  <div class="outermost">
    <layout class="layout" :hd="true" :ft="false">
      <!-- 右边按键 -->
      <div slot="rt"></div>
      <!-- 左边按键 -->
      <div slot="le" class="hd-left" @click="returnHome">
        <div></div>
        <span>公告消息</span>
      </div>
      <div
        class="Advertisement-container"
        v-for="item in afficheList"
        :key="item.id"
        @click="$router.push(`/afficheDetails?id=${item.id}`)"
      >
        <p>{{ item.releaseTime }}</p>
        <div class="Advertisement">
          <div class="title">
            <img src="../../assets/img/home/title.png" alt="" />
            <h3>{{ item.title }}</h3>
          </div>
          <p>
            {{ item.content }}
          </p>
        </div>
      </div>
    </layout>
  </div>
</template>

<script>
import Layout from '@/components/layout.vue'
import { getAfficheData } from '@/api/home'
export default {
  components: {
    Layout
  },
  data() {
    return {
      afficheList: []
    }
  },
  created() {
    this.getAfficheData()
  },
  methods: {
    getAfficheData() {
      getAfficheData({
        pageNo:1,
        pageSize:10
      }).then((res) => {
        this.afficheList = res.result.records
      })
    },
    // 返回上一页
    returnHome() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.outermost {
  background-color: #f2f3f5;
  .layout /deep/ .main {
    padding-bottom: 0;
  }
  .hd-left {
    display: flex;
    align-items: center;
    div {
      width: 10px;
      height: 10px;
      border-top: 2px solid #ffffff;
      border-right: 2px solid #ffffff;
      transform: rotate(225deg);
    }
    span {
      font-size: 18px;
      margin-left: 4px;
    }
  }
  .Advertisement-container {
    p {
      margin-top: 16px;
     
      height: 17px;
      font-size: 12px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: center;
      color: #868a93;
    }
  }
  .Advertisement {
    padding-top: 15px;
    margin-top: 20px;
    margin-left: 10px;
    width: 355px;
    height: 142px;
    background: #ffffff;
    border-radius: 8px;
    .title {
      margin-left: 15px;
      display: flex;
      align-items: center;
      h3 {
        margin-left: 8px;
      }
    }
    p {
      margin-top: 12px;
      margin-left: 17px;
      width: 323px;
      height: 72px;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #464a55;
      line-height: 24px;
    }
  }
}
</style>